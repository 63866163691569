import './App.css';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './Home';
import NavBar from './NavBar';
import { Routes, Route } from 'react-router-dom';
import CreateEditorList from './pages/CreateEditorList';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { EmailAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { useState } from 'react';
import { auth } from './lib/firebase.js';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './lib/apolloClient';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CreateEditPost from './pages/CreateEditPost';
import CreateEditAD from './pages/CreateEditAD';
import TagPosts from './pages/TagPosts';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#7D7AFF',
      },
    },
  });

  const [authenticated, setAuthenticated] = useState(false);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  });

  const uiConfig = {
    signInSuccessUrl: '/',
    signInOptions: [EmailAuthProvider.PROVIDER_ID],
  };

  return authenticated ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <Box>
            <NavBar />
          </Box>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/horse/create" element={<CreateEditorList />} />
            <Route
              path="/horse/edit/:id"
              element={<CreateEditorList isEditPage />}
            />
            <Route path="/post/create" element={<CreateEditPost />} />
            <Route path="/post/edit/:id" element={<CreateEditPost />} />
            <Route path="/ad/create" element={<CreateEditAD />} />
            <Route path="/ad/edit/:id" element={<CreateEditAD />} />
            <Route path="/tag-posts" element={<TagPosts />} />
          </Routes>
        </ThemeProvider>
      </ApolloProvider>
    </LocalizationProvider>
  ) : (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
  );
}

export default App;
