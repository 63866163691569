import dayjs, { Dayjs } from "dayjs";
import { ICloudflare, ICloudflareVideo, IMedia, IOption } from "./model";

export function isCloudflareVideo(obj: ICloudflare): obj is ICloudflareVideo {
  return (obj as ICloudflareVideo)?.playback !== undefined;
}

export function cloudflareImageWidth(num: number) {
  return Math.round(num / 100) * 100 + 150;
}

export function constructImageUrl(
  media: IMedia[] | undefined,
  size: "full" | "avatar" | number = "full"
) {
  if (!media?.length) {
    return;
  }

  const cf = media[0].cloudflare;

  if (isCloudflareVideo(cf)) {
    return;
  }

  const s = size === "full" ? 500 : size === "avatar" ? 50 : size;
  const w = cloudflareImageWidth(s);
  return `https://imagedelivery.net/${process.env.REACT_APP_CLOUDFLARE_ACCOUNT_ID}/${cf.id}/w=${w}`;
}

export function constructVideoUrl(media: IMedia[] | undefined) {
  if (!media?.length) {
    return;
  }

  const cf = media[0].cloudflare;

  if (!isCloudflareVideo(cf)) {
    return;
  }

  const url = cf.playback.dash || cf.playback.hls;
  const id = url.split("/")[3];

  return `https://cloudflarestream.com/${id}/iframe`;
}

export const checkPublishActive = (
  startDate: Dayjs | null,
  endDate: Dayjs | null
) => {
  if (!startDate) return "INACTIVE";
  if (dayjs(startDate).isAfter(Date.now())) return "INACTIVE";
  if (endDate && dayjs(endDate).isBefore(Date.now())) return "INACTIVE";
  return "ACTIVE";
};

export function preventSubmit(keyEvent: React.KeyboardEvent<HTMLInputElement>) {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

export function mapKeysToOptions(obj: any, keys: string[]) {
  const options = keys.map((key) => {
    const value = obj[key];
    if (value) {
      return { label: value, value: key };
    }
    return null;
  });

  return options.filter((item) => item !== null) as IOption[];
}
