import { gql } from "@apollo/client";

export const GET_EDITORLISTS = gql`
  query editorLists($id: String) {
    editorLists(id: $id) {
      id
      title {
        value
        locale
      }
      subtitle {
        value
        locale
      }
      shape
      bannerSv {
        url
      }
      bannerEn {
        url
      }
      hero {
        url
      }
      createdAt
      updatedAt
      horses {
        id
        name
        breed
        category
        profilePictures {
          cloudflare {
            ... on CloudflareImage {
              id
              variants
            }
          }
        }
        disciplines
        numberOfFollowers
      }
      posts {
        id
        title
        caption
        media {
          cloudflare {
            ... on CloudflareImage {
              id
              variants
            }
            ... on CloudflareVideo {
              playback {
                hls
                dash
              }
            }
          }
        }
        categories
        disciplines
        pending
        publishedAt
        updatedAt
      }
      kind
      link
      disciplines
      breeds
      status
      sponsored
      mandatory
      publishArea {
        location
        startDate
        endDate
      }
      notifiableUsers {
        id
        name
        isNotified
      }
    }
  }
`;

export const CREATE_EDITORLIST = gql`
  mutation createEditorList($input: EditorListInput) {
    createEditorList(input: $input) {
      id
      title {
        value
        locale
      }
      subtitle {
        value
        locale
      }
      shape
      hero {
        url
      }
      bannerSv {
        url
      }
      bannerEn {
        url
      }
      createdAt
      updatedAt
      horses {
        id
      }
      posts {
        id
      }
      kind
      link
      disciplines
      breeds
      status
      sponsored
      publishArea {
        location
        startDate
        endDate
      }
    }
  }
`;

export const EDIT_EDITORLIST = gql`
  mutation editEditorList($input: EditorListInput) {
    editEditorList(input: $input) {
      id
      title {
        value
        locale
      }
      subtitle {
        value
        locale
      }
      shape
      hero {
        url
      }
      bannerSv {
        url
      }
      bannerEn {
        url
      }
      createdAt
      updatedAt
      horses {
        id
      }
      posts {
        id
      }
      kind
      link
      disciplines
      breeds
      status
      publishArea {
        location
        startDate
        endDate
      }
    }
  }
`;

export const TAG_POST_WITH_DISCIPLINES = gql`
  mutation TagPostWithDisciplines($input: TagPostWithDisciplinesInput!) {
    tagPostWithDisciplines(input: $input) {
      id
      title
      disciplines
      updatedAt
    }
  }
`;

export const GET_HORSES = gql`
  query horses {
    horses {
      id
      name
      breed
      profilePictures {
        cloudflare {
          ... on CloudflareImage {
            id
            variants
          }
        }
      }
      disciplines
    }
  }
`;

export const HORSE_EQUIPE = gql`
  query equipeHorses($url: String!) {
    equipeHorses(url: $url) {
      id
      name
      caption: breed
      profilePictures {
        cloudflare {
          ... on CloudflareImage {
            variants
            id
          }
        }
      }
      disciplines
    }
  }
`;

export const HORSE_PAGINATION = gql`
  query HorseSearchPagination($query: PaginationSearchQuery!) {
    horseSearchPagination(query: $query) {
      pageInfo {
        current
        total
        size
      }
      items {
        id
        name
        caption: breed
        profilePictures {
          cloudflare {
            ... on CloudflareImage {
              variants
              id
            }
          }
        }
        disciplines
      }
    }
  }
`;

export const REMOVE_EDITORLIST = gql`
  mutation deleteEditorList($id: String!) {
    deleteEditorList(id: $id)
  }
`;

export const POST_PAGINATION = gql`
  query PostSearchPagination($query: PostPaginationQuery!) {
    postsPagination(query: $query) {
      pageInfo {
        current
        total
        size
      }
      items {
        id
        name: title
        caption
        categories
        disciplines
        starred
        profilePictures: media {
          cloudflare {
            ... on CloudflareImage {
              variants
              id
            }
            ... on CloudflareVideo {
              playback {
                hls
                dash
              }
            }
          }
        }
      }
    }
  }
`;

export const NOTIFY_USERS = gql`
  mutation NotifyUsers($input: NotifyUsersInput!) {
    notifyUsers(input: $input) {
      id
      notifiableUsers {
        id
        name
        isNotified
      }
    }
  }
`;
