import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DropzoneArea from "./dropzoneArea";
import { Box, Divider, Grid, styled, TextField } from "@mui/material";
import { IFileObject } from "../../model";
import { storage } from "../../lib/firebase";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { preventSubmit } from "../../utils";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface PageProps {
  type: "Ad" | "Hero";
  titleSv: string;
  link: string;
  svFiles: IFileObject[];
  enFiles?: IFileObject[];
  errorMsg?: string;
  sponsored?: boolean;
  setSponsored?: (value: boolean) => void;
  updateLink: (value: string) => void;
  setSvFiles: React.Dispatch<React.SetStateAction<IFileObject[]>>;
  setEnFiles?: React.Dispatch<React.SetStateAction<IFileObject[]>>;
  updateErrorMsg?: () => void;
  updateBannerUrl: (value: string, isSv: boolean) => void;
}

export default function BannerUploader({
  type,
  titleSv,
  link,
  svFiles,
  enFiles,
  errorMsg = "",
  sponsored = false,
  setSponsored,
  updateLink,
  setSvFiles,
  setEnFiles,
  updateErrorMsg,
  updateBannerUrl,
}: PageProps) {
  const [expand, setExpand] = useState<boolean>(type === "Ad");
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [alertMsg, setAlertMsg] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("error");

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (updateErrorMsg) updateErrorMsg();
    setShowAlert(false);
  };

  const handleAdd = async (newFiles: IFileObject[], isSv = false) => {
    if (isSv) {
      if (titleSv === "") {
        setAlertMsg("Please type title first.");
        setSeverity("error");
        setShowAlert(true);
        return;
      }
    } else {
      if (svFiles.length === 0) {
        setAlertMsg("Please upload default banner first.");
        setSeverity("error");
        setShowAlert(true);
        return;
      }
    }
    //upload image
    if (newFiles.length > 0 && storage) {
      console.log("uploading...");
      const file = newFiles[0].file;
      const storageRef = ref(
        storage,
        `banner/${isSv ? "sv" : "en"}/${titleSv}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      setIsUploading(true);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // setProgresspercent(progress);
          if (progress === 100) setIsUploading(false);
        },
        (error) => {
          console.log(error);
          setAlertMsg("Failed to upload banner.");
          setSeverity("error");
          setShowAlert(true);
          setIsUploading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateBannerUrl(downloadURL, isSv);
            if (isSv) {
              setSvFiles(newFiles);
              setAlertMsg("Default banner is successfully uploaded.");
            } else if (setEnFiles) {
              setEnFiles(newFiles);
              setAlertMsg("English banner is successfully uploaded.");
            }
            setSeverity("success");
            setShowAlert(true);
          });
        }
      );
    }
  };
  const handleDelete = async (isSv = false) => {
    if (!storage) {
      setAlertMsg("Storage is not connected. Please try again later.");
      setSeverity("error");
      setShowAlert(true);
      return;
    }
    setIsUploading(true);
    if (isSv) {
      if ((enFiles?.length || 0) > 0) {
        setAlertMsg("Please remove English banner first.");
        setSeverity("error");
        setShowAlert(true);
        setIsUploading(false);
        return;
      }
      try {
        const bannerSvUrl = svFiles[0].data as string;
        if (
          bannerSvUrl?.startsWith(
            "https://firebasestorage.googleapis.com/v0/b/floccs-admin.appspot.com"
          )
        ) {
          await deleteObject(ref(storage, bannerSvUrl));
        }
        setIsUploading(false);
        setSvFiles([]);
        updateBannerUrl("", true);
        setAlertMsg("Removed default banner.");
        setSeverity("info");
      } catch (err) {
        console.log(err);
        setIsUploading(false);
        setAlertMsg("Failed to removed default banner.");
        setSeverity("error");
      }
    } else {
      if (enFiles?.length && setEnFiles) {
        try {
          const bannerEnUrl = enFiles[0].data as string;
          if (
            bannerEnUrl?.startsWith(
              "https://firebasestorage.googleapis.com/v0/b/floccs-admin.appspot.com"
            )
          ) {
            await deleteObject(ref(storage, bannerEnUrl));
          }
          setIsUploading(false);
          setEnFiles([]);
          updateBannerUrl("", false);
          setAlertMsg("Removed English banner.");
          setSeverity("info");
        } catch (err) {
          console.log(err);
          setIsUploading(false);
          setAlertMsg("Failed to removed English banner.");
          setSeverity("error");
        }
      }
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (errorMsg !== "") {
      setAlertMsg(errorMsg);
      setSeverity("error");
      setShowAlert(true);
    }
  }, [errorMsg]);

  return (
    <>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      <Accordion
        expanded={expand || (enFiles?.length || 0) > 0 || svFiles.length > 0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          <Typography variant="h6">
            {type === "Ad" ? "AD banners" : "Hero image (Optional)"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ position: "relative" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} display="flex" alignItems="center">
              <TextField
                fullWidth
                size="small"
                id="upload-link"
                label="Banner URL (optional)"
                onChange={(e) => updateLink(e.target.value)}
                onKeyDown={preventSubmit}
                style={{ margin: 0 }}
                onBlur={() => {
                  if (!link.startsWith("https://") && link !== "") {
                    setAlertMsg("Invalid URL");
                    setSeverity("error");
                    setShowAlert(true);
                  }
                }}
                value={link}
              />
              {link.length > 0 && (
                <a href={link} target="_blank" rel="noreferrer">
                  <OpenInNewIcon />
                </a>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sponsored}
                      onChange={() => {
                        if (setSponsored) setSponsored(!sponsored);
                      }}
                    />
                  }
                  label="Add sponsored badge to banner"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: 1, px: 1, "& > a": { display: "flex" } }}
              >
                Default banner
              </Typography>
              <DropzoneArea
                files={svFiles}
                handleAdd={(newFiles) => {
                  handleAdd(newFiles, true);
                }}
                handleDelete={() => {
                  handleDelete(true);
                }}
              />
            </Grid>
            {enFiles ? (
              <Grid item xs={12} md={6}>
                <Typography
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ pb: 1, px: 1, "& > a": { display: "flex" } }}
                >
                  English banner (Optional)
                </Typography>
                <DropzoneArea
                  files={enFiles}
                  handleAdd={handleAdd}
                  handleDelete={() => {
                    handleDelete();
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          {isUploading && (
            <Box
              justifyContent="center"
              alignItems="center"
              display="flex"
              sx={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                background: "rgba(0, 0, 0, 0.3)",
                backdropFilter: "blur(3px)",
                zIndex: 999999,
              }}
            >
              <CircularProgress size={80} color="secondary" />
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
