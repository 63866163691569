import Disciplines from "../../constant/disciplines.json";
import Breeds from "../../constant/breeds.json";
import { Divider } from "@mui/material";
import SelectAutocomplete from "../selectAutocomplete";
import { IOption } from "../../model";

interface PageProps {
  disciplines: IOption[];
  breeds: IOption[];
  setDisciplines: React.Dispatch<React.SetStateAction<IOption[]>>;
  setBreeds: React.Dispatch<React.SetStateAction<IOption[]>>;
}

export default function SelectTagGroup({
  disciplines,
  breeds,
  setDisciplines,
  setBreeds,
}: PageProps) {
  return (
    <>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      <SelectAutocomplete
        limitTags={1}
        label="Disciplines"
        placeholder="Search disciplines.."
        disableCloseOnSelect
        options={Object.entries(Disciplines.disciplines).map(
          ([key, value]) => ({
            label: value,
            value: key,
          })
        )}
        selected={disciplines}
        setSelected={(value) => setDisciplines(value as IOption[])}
      />
      <SelectAutocomplete
        limitTags={1}
        label="Breeds"
        placeholder="Search breeds.."
        disableCloseOnSelect
        options={Object.entries(Breeds.breeds).map(([key, value]) => ({
          label: value,
          value: key,
        }))}
        selected={breeds}
        setSelected={(value) => setBreeds(value as IOption[])}
      />
    </>
  );
}
