export const INITIAL_PUBLIC_AREA = [
  {
    title: "Publish on discipline page(s)",
    startDate: null,
    endDate: null,
    location: "DISCIPLINE",
  },
  {
    title: "Publish on breed page(s)",
    startDate: null,
    endDate: null,
    location: "BREED",
  },
  {
    title: "Publish on home page",
    startDate: null,
    endDate: null,
    location: "HOME",
  },
];

export const HORSE_IMAGE_SHAPES = [
  {
    value: "SQUARE",
    label: "Kvadratisk",
  },
  {
    value: "ROUND",
    label: "Rund",
  },
  {
    value: "RECTANGLE",
    label: "Rektangulär",
  },
];

export const PUBLISH_TITLES: any = {
  DISCIPLINE: "Publish on discipline page(s)",
  BREED: "Publish on breed page(s)",
  HOME: "Publish on home page",
};
