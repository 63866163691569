import Grid, { GridProps } from "@mui/material/Grid";
import { useTheme, useMediaQuery } from "@mui/material";

interface Props {
  children: JSX.Element[];
  gridProps: GridProps;
}

export default function ResponsiveGrid({ children, gridProps }: Props) {
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesSM = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const matchesMD = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));

  let columns: number;
  if (matchesXS) {
    columns = 2; // 2 columns on extra-small devices
  } else if (matchesSM) {
    columns = 3; // 3 columns on small devices
  } else if (matchesMD) {
    columns = 4; // 4 columns on medium devices
  } else if (matchesLG) {
    columns = 5; // 5 columns on large devices
  }

  return (
    <Grid container spacing={2} {...gridProps}>
      {children.map((item) => (
        <Grid item key={item.key}>
          {item}
        </Grid>
      ))}
    </Grid>
  );
}
