import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { makeStyles, withStyles, createStyles } from "@mui/styles";
import Chip from "@mui/material/Chip";
import { ISelectAutocomplete } from "../../model";
import { preventSubmit } from "../../utils";

const useStyles = makeStyles(() => ({
  tag: {
    backgroundColor: "#7B61FF!important",
    color: "white!important",
    borderRadius: "50px",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  root: {
    display: "flex",
  },
  deleteIcon: {
    color: "white",
  },
}));

const AutocompleteTag = withStyles((theme) =>
  createStyles({
    deleteIcon: {
      color: "white!important",
      "&:hover": {
        opacity: "0.8",
      },
    },
  })
)(Chip);

function SelectAutocomplete({
  label,
  placeholder = "",
  limitTags = -1,
  options,
  selected,
  setSelected,
  onBlur,
  disableCloseOnSelect = false,
  multiple = true,
}: ISelectAutocomplete) {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      disableCloseOnSelect={disableCloseOnSelect}
      id={`select-${label}-tags`}
      options={options}
      value={selected}
      onChange={(_e, value) => setSelected(value)}
      classes={{ tag: classes.tag, root: classes.root }}
      onBlur={(e: any) => {
        if (onBlur) {
          onBlur();
        }
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <AutocompleteTag label={option.label} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth
          onKeyDown={preventSubmit}
        />
      )}
    />
  );
}

export default SelectAutocomplete;
