import { useEffect, useMemo, useState } from "react";
import {
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Button,
  Box,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import {
  IEquipeResult,
  IEquipeVars,
  IOption,
  ISearchPaginationData,
  ISearchPaginationVars,
  ISelectedItem,
} from "../../model";
import SelectGrid from "./selectGrid";
import { HORSE_EQUIPE, HORSE_PAGINATION, POST_PAGINATION } from "../../gql";
import { preventSubmit } from "../../utils";
import SelectAutocomplete from "../selectAutocomplete";
import SearchIcon from "@mui/icons-material/Search";
import Disciplines from "../../constant/disciplines.json";
import Breeds from "../../constant/breeds.json";
import Categories from "../../constant/categories.json";

const useStyles = makeStyles(() => ({
  paginationItem: {
    backgroundColor: "#FFF",
    color: "#000000",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  selected: {
    backgroundColor: "#7B61FF!important",
    boxShadow:
      "0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14), 0px 5px 14px rgba(0, 0, 0, 0.12)",
    color: "#FFFFFF!important",
  },
}));

interface PageProps {
  isPost?: boolean;
  selectedDisciplines?: string[];
  selectedIds: string[];
  handleSelect: (selectedItem: ISelectedItem) => void;
}

const SEARCH_LIMIT = 25;
const REG_EXP_FOR_EQUIP =
  /https:\/\/online\.equipe\.com\/[a-zA-Z]+\/competitions\/\d+\/horses/;

export default function SelectPagination({
  isPost,
  selectedIds,
  handleSelect,
}: PageProps) {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<IOption[]>([]);
  const [discipline, setDiscipline] = useState<IOption | null>(null);
  const [breed, setBreed] = useState<IOption | null>(null);

  const [equipeUrl, setEquipeUrl] = useState<string>("");

  const [list, setList] = useState<ISelectedItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [typedPage, setTypedPage] = useState<number>();

  const isValidUrl = useMemo(() => {
    return REG_EXP_FOR_EQUIP.test(equipeUrl);
  }, [equipeUrl]);

  const { loading: pageLoading, data } = useQuery<
    ISearchPaginationData,
    ISearchPaginationVars
  >(isPost ? POST_PAGINATION : HORSE_PAGINATION, {
    variables: {
      query: isPost
        ? {
            categories: categories?.map((c) => c.value),
            discipline: discipline?.value,
            breed: breed?.value,
            page,
            size: SEARCH_LIMIT,
          }
        : {
            searchTerm,
            page,
            size: SEARCH_LIMIT,
          },
    },
    skip: equipeUrl !== "",
  });

  const { loading: equipeLoading, data: equipeData } = useQuery<
    IEquipeResult,
    IEquipeVars
  >(HORSE_EQUIPE, {
    variables: {
      url: equipeUrl,
    },
    skip: equipeUrl === "" || !isValidUrl,
  });

  useEffect(() => {
    if (data && equipeUrl === "") {
      const result = isPost ? data.postsPagination : data.horseSearchPagination;
      if (result) {
        const { items, pageInfo } = result;
        setTotal(pageInfo.total);
        if (pageInfo.total < page) setPage(1);
        setList(
          items.map((item) => ({
            id: item.id,
            title: item.name,
            description: item.caption,
            img: item.profilePictures,
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, equipeUrl]);

  useEffect(() => {
    if (isValidUrl && equipeData) {
      const result = equipeData.equipeHorses;
      setList(
        result.map((item) => ({
          id: item.id,
          title: item.name,
          description: item.caption,
          img: item.profilePictures,
        }))
      );
    }
  }, [isValidUrl, equipeData]);

  const handleChange = (e: any, value?: number) => {
    if (value && value < total) {
      setPage(value);
    }
    setTypedPage(undefined);
  };

  return (
    <>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      {isPost ? (
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <SelectAutocomplete
              label="Categories"
              placeholder="Search categories.."
              disableCloseOnSelect
              options={Object.entries(Categories.categories).map(
                ([key, value]) => ({
                  label: value,
                  value: key,
                })
              )}
              selected={categories}
              setSelected={(value) => {
                setDiscipline(null);
                setBreed(null);
                setCategories(value as IOption[]);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SelectAutocomplete
              multiple={false}
              limitTags={1}
              label="Discipline"
              placeholder="Search discipline.."
              disableCloseOnSelect
              options={Object.entries(Disciplines.disciplines).map(
                ([key, value]) => ({
                  label: value,
                  value: key,
                })
              )}
              selected={discipline}
              setSelected={(value) => {
                setCategories([]);
                setBreed(null);
                setDiscipline(value as IOption);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SelectAutocomplete
              multiple={false}
              limitTags={1}
              label="Breed"
              placeholder="Search breed.."
              disableCloseOnSelect
              options={Object.entries(Breeds.breeds).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              selected={breed}
              setSelected={(value) => {
                setCategories([]);
                setDiscipline(null);
                setBreed(value as IOption);
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} md={6} display="flex">
            <TextField
              fullWidth
              label="Sök häst..."
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={preventSubmit}
              value={searchTerm}
            />
          </Grid>
          <Grid item xs={12} md={6} display="flex" alignItems="center">
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel htmlFor="equipe-url">
                Optional: Insert equipe URL to find horses in competition
              </InputLabel>
              <OutlinedInput
                id="equipe-url"
                type="text"
                fullWidth
                label="Optional: Insert equipe URL to find horses in competition"
                aria-describedby="component-error-text"
                onChange={(e) => setEquipeUrl(e.target.value)}
                onKeyDown={preventSubmit}
                value={equipeUrl}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
              {equipeUrl !== "" && !isValidUrl && (
                <FormHelperText id="component-error-text" error>
                  Invalid URL
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
      <SelectGrid
        items={equipeUrl !== "" && !isValidUrl ? [] : list}
        selected={selectedIds}
        handleSelect={handleSelect}
        loading={pageLoading || equipeLoading}
      />
      <Container
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
        sx={{ my: 3 }}
      >
        <Pagination
          count={total || 1}
          page={page}
          onChange={handleChange}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.paginationItem,
                selected: classes.selected,
              }}
              {...item}
            />
          )}
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-number"
            label="Page"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setTypedPage(Number(e.target.value))}
            size="small"
            sx={{ width: 100 }}
          />
          <Button onClick={() => handleChange(undefined, typedPage)}>
            Apply
          </Button>
        </Box>
      </Container>
    </>
  );
}
