import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { useMutation } from "@apollo/client";
import { TAG_POST_WITH_DISCIPLINES } from "../../gql";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { Skeleton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Checkbox from "@mui/material/Checkbox";
import SelectAutocomplete from "../selectAutocomplete";
import Disciplines from "../../constant/disciplines.json";
import { constructImageUrl, mapKeysToOptions } from "../../utils";
import { IOption, IPaginationItem } from "../../model";
import { isCloudflareVideo, constructVideoUrl } from "../../utils";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function PostCard({
  item,
  refetch,
}: {
  item: IPaginationItem;
  refetch: () => void;
}) {
  const [tagPostWithDisciplines, { loading }] = useMutation(
    TAG_POST_WITH_DISCIPLINES
  );
  const { id, name, caption, disciplines, profilePictures, starred } = item;
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const [star, setStar] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDisciplines, setDisciplines] = useState<IOption[]>([]);

  const updateDisciplines = async () => {
    tagPostWithDisciplines({
      variables: {
        input: {
          postId: id,
          disciplines: selectedDisciplines.map((d) => d.value),
          starred: star,
        },
      },
    }).then(() => {
      handleClose();
      refetch();
    });
  };

  const updateStar = () => {
    setStar(!star);
    tagPostWithDisciplines({
      variables: {
        input: {
          postId: id,
          disciplines: selectedDisciplines.map((d) => d.value),
          starred: !star,
        },
      },
    }).then(() => {
      refetch();
    });
  };

  const handleClickOpen = () => {
    if (
      JSON.stringify([...(disciplines || [])].sort()) !==
      JSON.stringify([...selectedDisciplines.map((d) => d.value)].sort())
    ) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (disciplines) {
      setDisciplines(mapKeysToOptions(Disciplines.disciplines, disciplines));
    } else {
      setDisciplines([]);
    }
    setOpen(false);
  };

  const handleError = useCallback(() => {
    setImgSrc(null);
  }, []);

  useEffect(() => {
    if (isCloudflareVideo(profilePictures[0].cloudflare)) {
      if (constructVideoUrl(profilePictures)) {
        setVideoSrc(constructVideoUrl(profilePictures) || "");
        const iframe = document.createElement("iframe");
        iframe.src = constructVideoUrl(profilePictures) || "";
        iframe.onerror = () => {
          setVideoSrc(null);
        };
      }
    } else {
      if (constructImageUrl(profilePictures)) {
        setImgSrc(constructImageUrl(profilePictures) || "");
        const img = new Image();
        img.src = constructImageUrl(profilePictures) || "";
        img.onerror = () => {
          setImgSrc(null);
        };
      }
    }
  }, [profilePictures]);

  useEffect(() => {
    if (disciplines) {
      setDisciplines(mapKeysToOptions(Disciplines.disciplines, disciplines));
    }
    if (starred) {
      setStar(true);
    } else {
      setStar(false);
    }
  }, [disciplines, starred]);

  return (
    <>
      <Paper
        sx={{
          m: 2,
          overflow: "hidden",
          maxWidth: 500,
          flexGrow: 1,
          height: "fit-content",
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container>
          {isCloudflareVideo(profilePictures[0].cloudflare) ? (
            videoSrc ? (
              <iframe
                title="Video"
                style={{ width: "100%", minHeight: "300px", border: "none" }}
                src={videoSrc}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen
              />
            ) : (
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%" }}
                height={300}
                animation="wave"
              />
            )
          ) : imgSrc ? (
            <Grid item xs={12}>
              <Img
                alt="complex"
                src={imgSrc}
                onError={handleError}
                width={"100%"}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm container sx={{ p: 2 }}>
            <Grid item xs container direction="column" width="100%">
              <Grid
                item
                xs
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", m: 0 }}
                >
                  {name ?? ""}
                </Typography>
                <Checkbox
                  checked={star}
                  onChange={() => updateStar()}
                  icon={
                    <StarBorderIcon
                      fontSize="large"
                      sx={{ color: "#FB8C00" }}
                    />
                  }
                  checkedIcon={
                    <StarIcon fontSize="large" sx={{ color: "#FB8C00" }} />
                  }
                />
              </Grid>
              {caption && (
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {caption}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <SelectAutocomplete
          label="Disciplines"
          placeholder="Search disciplines.."
          limitTags={2}
          disableCloseOnSelect
          options={Object.entries(Disciplines.disciplines).map(
            ([key, value]) => ({
              label: value,
              value: key,
            })
          )}
          selected={selectedDisciplines}
          setSelected={(value) => setDisciplines(value as IOption[])}
          onBlur={handleClickOpen}
        />
      </Paper>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle sx={{ textAlign: "center", my: 5 }}>
          Are you sure you want to add disciplines?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={updateDisciplines}>
            {loading ? "Updating..." : "Update"}
          </Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
