import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_EDITORLIST, EDIT_EDITORLIST, GET_EDITORLISTS } from "../gql";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  IPublishItem,
  ISelectedItem,
  IEditorList,
  IHorse,
  IFileObject,
  IOption,
} from "../model";
import { mapKeysToOptions, preventSubmit } from "../utils";
import PreviewSelectedItems from "../components/previewSelectedItems";
import BannerUploader from "../components/bannerUploader";
import {
  HORSE_IMAGE_SHAPES,
  INITIAL_PUBLIC_AREA,
  PUBLISH_TITLES,
} from "../constant";
import SelectTagGroup from "../components/selectTagGroup";
import PublishListGroup from "../components/publishListGroup";
import SelectPagination from "../components/selectPagination";
import Disciplines from "../constant/disciplines.json";
import Breeds from "../constant/breeds.json";

interface Props {
  isEditPage?: boolean;
}

function CreateEditorList({ isEditPage = false }: Props) {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: editorListData, loading: editorListLoading } = useQuery<{
    editorLists: IEditorList[];
  }>(GET_EDITORLISTS, {
    variables: {
      id: id,
    },
    skip: !id,
  });

  const [disciplines, setDisciplines] = useState<IOption[]>([]);
  const [breeds, setBreeds] = useState<IOption[]>([]);
  const [createEditorList, { error, loading }] = useMutation(CREATE_EDITORLIST);
  const [editEditorList] = useMutation(EDIT_EDITORLIST);
  const [sponsored, setSponsored] = useState<boolean>(false);

  const [heroUrl, setHeroUrl] = useState<string>("");
  const [heroFiles, setHeroFiles] = useState<IFileObject[]>([]);

  const [titleSv, setTitleSv] = useState<string>("");
  const [titleEn, setTitleEn] = useState<string>("");
  const [subtitleSv, setSubtitleSv] = useState<string>("");
  const [subtitleEn, setSubtitleEn] = useState<string>("");

  const [link, setLink] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [shape, setShape] = useState<string | undefined>("SQUARE");
  const [selectedHorses, setSelectedHorses] = useState<ISelectedItem[]>([]);

  const [publishArea, setPublishArea] =
    useState<IPublishItem[]>(INITIAL_PUBLIC_AREA);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (link !== "" && !link.startsWith("https://")) {
      setErrorMsg("Invalid url.");
      return;
    }
    const inputData = {
      heroUrl,
      shape,
      horseIds: selectedHorses.map((horse) => horse.id),
      disciplines: disciplines?.map((d) => d.value),
      breeds: breeds?.map((b) => b.value),
      publishArea: publishArea.map((item) => ({
        location: item.location,
        startDate: item.startDate,
        endDate: item.endDate,
      })),
      title: [
        {
          value: titleSv,
          locale: "sv_SE",
        },
        {
          value: titleEn,
          locale: "en_US",
        },
      ],
      subtitle: [
        {
          value: subtitleSv,
          locale: "sv_SE",
        },
        {
          value: subtitleEn,
          locale: "en_US",
        },
      ],
      kind: "HORSE",
      link,
      sponsored,
    };
    (isEditPage && id
      ? editEditorList({
          variables: {
            input: {
              id,
              ...inputData,
            },
          },
        })
      : createEditorList({
          variables: {
            input: inputData,
          },
        })
    ).then(() => navigate("/"));
  };

  useEffect(() => {
    if (id && editorListData && editorListData.editorLists.length > 0) {
      const selectedData = editorListData.editorLists[0];
      const tempPublicArea = selectedData.publishArea?.map((area) => ({
        title: PUBLISH_TITLES[area.location],
        startDate: area.startDate,
        endDate: area.endDate,
        location: area.location,
      }));

      if (selectedData.hero?.url) {
        fetch(selectedData.hero.url).then(async (r) => {
          const blob = await r.blob();
          const file = new File([blob], "image.jpg", { type: blob.type });
          setHeroFiles([{ file, data: selectedData.hero?.url ?? "" }]);
        });
      }

      setHeroUrl(selectedData.hero?.url || "");
      setLink(selectedData.link || "");
      setShape(selectedData.shape);
      setBreeds(mapKeysToOptions(Breeds.breeds, selectedData.breeds || []));
      setDisciplines(
        mapKeysToOptions(
          Disciplines.disciplines,
          selectedData.disciplines || []
        )
      );
      if (tempPublicArea && tempPublicArea.length > 0)
        setPublishArea(tempPublicArea);
      if (selectedData?.sponsored) {
        setSponsored(true);
      } else {
        setSponsored(false);
      }

      setSelectedHorses(
        selectedData.horses?.map((horse) => ({
          id: horse.id,
          title: horse.name,
          description: [
            (horse as IHorse).breed,
            ...(horse.disciplines || []),
          ].join(", "),
          img: horse.profilePictures,
        })) || []
      );

      setTitleSv(
        selectedData?.title && selectedData.title?.length > 0
          ? selectedData?.title[0]?.value
          : ""
      );
      setTitleEn(
        selectedData?.title && selectedData.title?.length > 1
          ? selectedData?.title[1]?.value
          : ""
      );
      setSubtitleSv(
        selectedData?.subtitle && selectedData.subtitle?.length > 0
          ? selectedData?.subtitle[0]?.value
          : ""
      );
      setSubtitleEn(
        selectedData?.subtitle && selectedData.subtitle?.length > 1
          ? selectedData?.subtitle[1]?.value
          : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, editorListData]);

  if (loading || editorListLoading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="600px"
      >
        <CircularProgress size={50} sx={{ color: "#7B61FF" }} />
      </Box>
    );
  if (error) return <Typography>Något gick fel: {error.message}</Typography>;

  function handleSelect(selectedHorse: ISelectedItem) {
    setSelectedHorses(
      selectedHorses.some((horse) => horse.id === selectedHorse.id)
        ? selectedHorses.filter((horse) => horse.id !== selectedHorse.id)
        : [selectedHorse, ...selectedHorses]
    );
  }

  function updateBannerUrl(value: string) {
    setHeroUrl(value);
    setErrorMsg("");
  }

  return (
    <Container sx={{ my: 2, mx: "auto" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Typography variant="h4" textAlign="right" sx={{ mb: 2 }}>
          {id ? "Edit" : "Create"} Horse
        </Typography>

        <Grid container gap={1}>
          <Grid item xs={12} md={5}>
            <TextField
              required
              fullWidth
              id="outlined-sv-title-required"
              label="Title"
              onChange={(e) => setTitleSv(e.target.value)}
              value={titleSv}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-en-title"
              label="Title English (Optional)"
              onChange={(e) => setTitleEn(e.target.value)}
              value={titleEn}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-sv-subtitle"
              label="Subitle in Swedish"
              onChange={(e) => setSubtitleSv(e.target.value)}
              value={subtitleSv}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-en-subtitle"
              label="Subtitle in English"
              onChange={(e) => setSubtitleEn(e.target.value)}
              value={subtitleEn}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-shape-currency"
              select
              label="Shape"
              value={shape}
              onChange={(e) => setShape(e.target.value)}
              onKeyDown={preventSubmit}
            >
              {HORSE_IMAGE_SHAPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <SelectTagGroup
          disciplines={disciplines}
          breeds={breeds}
          setDisciplines={setDisciplines}
          setBreeds={setBreeds}
        />
        <PublishListGroup
          publishArea={publishArea}
          setPublishArea={setPublishArea}
        />
        <BannerUploader
          type="Hero"
          titleSv={titleSv}
          link={link}
          svFiles={heroFiles}
          errorMsg={errorMsg}
          updateLink={(value: string) => {
            setLink(value);
            setErrorMsg("");
          }}
          sponsored={sponsored}
          setSponsored={setSponsored}
          setSvFiles={setHeroFiles}
          updateBannerUrl={updateBannerUrl}
          updateErrorMsg={() => setErrorMsg("")}
        />
        <PreviewSelectedItems
          title="Horse"
          items={selectedHorses}
          handleSelect={handleSelect}
          setOrder={setSelectedHorses}
        />
        <SelectPagination
          selectedIds={selectedHorses.map((horse) => horse.id)}
          handleSelect={handleSelect}
        />

        <Divider variant="middle" sx={{ marginY: 2 }} />
        <Box sx={{ mt: 3, mx: "auto", width: 200, textAlign: "center" }}>
          <Button variant="outlined" type="submit">
            {isEditPage ? "Update List" : "Skapa lista"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default CreateEditorList;
