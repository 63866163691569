import { Divider } from '@mui/material';
import { IPublishItem } from '../../model';
import PublishListItem from '../publishListItem';

interface PageProps {
  publishArea: IPublishItem[];
  setPublishArea: React.Dispatch<React.SetStateAction<IPublishItem[]>>;
}

export default function PublishListGroup({
  publishArea,
  setPublishArea,
}: PageProps) {
  return (
    <>
      <Divider variant="middle" sx={{ marginY: 2 }} />
      {publishArea.map((item: IPublishItem, index: number) => (
        <PublishListItem
          key={`publish-area-${index}`}
          item={item}
          updateDate={(
            newDate:
              | Pick<IPublishItem, 'startDate'>
              | Pick<IPublishItem, 'endDate'>
          ) => {
            const updatedItem = { ...publishArea[index], ...newDate };
            setPublishArea((prevState) => [
              ...prevState.slice(0, index),
              updatedItem,
              ...prevState.slice(index + 1),
            ]);
          }}
        />
      ))}
    </>
  );
}
