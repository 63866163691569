import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  CircularProgress,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  IPaginationItem,
  ISearchPaginationData,
  ISearchPaginationVars,
} from "../model";
import { useQuery } from "@apollo/client";
import PostCard from "../components/postCard";
import { POST_PAGINATION } from "../gql";
import { Container } from "@mui/system";

const useStyles = makeStyles(() => ({
  paginationItem: {
    backgroundColor: "#FFF",
    color: "#000000",
    boxShadow:
      "0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
  selected: {
    backgroundColor: "#7B61FF!important",
    boxShadow:
      "0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14), 0px 5px 14px rgba(0, 0, 0, 0.12)",
    color: "#FFFFFF!important",
  },
}));

function TagPosts() {
  const classes = useStyles();

  const SEARCH_LIMIT = 25;
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [posts, setPosts] = useState<IPaginationItem[]>([]);
  /* const [tagged, setTagged] = useState<boolean>(false); */

  const { loading, data, error, refetch } = useQuery<
    ISearchPaginationData,
    ISearchPaginationVars
  >(POST_PAGINATION, {
    variables: {
      query: {
        page,
        size: SEARCH_LIMIT,
      },
    },
  });

  const { items, pageInfo } = data?.postsPagination || {
    items: [],
    pageInfo: { current: 0, total: 0, size: 0 },
  };

  useEffect(() => {
    if (data) {
      setTotal(pageInfo.total);
      if (pageInfo.total < page) setPage(1);
      setPosts(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (e: any, value: number) => {
    setPage(value);
  };

  return (
    <Container maxWidth={false}>
      {/* <ButtonGroup
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
        sx={{
          my: 3,
          width: "300px",
          border: "2px solid #7D7AFF",
          borderRadius: "7px",
        }}
      >
        <Button
          onClick={() => setTagged(false)}
          sx={{
            width: "50%",
            backgroundColor: tagged ? "#FFF" : "#7D7AFF",
            color: tagged ? "#7D7AFF" : "#FFF",
            textTransform: "uppercase",
          }}
        >
          Un-Tagged
        </Button>
        <Button
          onClick={() => setTagged(true)}
          sx={{
            width: "50%",
            backgroundColor: tagged ? "#7D7AFF" : "#FFF",
            color: tagged ? "#FFF" : "#7D7AFF",
            textTransform: "uppercase",
          }}
        >
          Tagged
        </Button>
      </ButtonGroup> */}
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="600px"
        >
          <CircularProgress size={50} sx={{ color: "#7B61FF" }} />
        </Box>
      ) : (
        <Grid container rowSpacing={2} spacing={2} sx={{ minHeight: "70vh" }}>
          {posts.map((post) => (
            <Grid item xs={6} md={3} key={post.id}>
              <PostCard item={post} refetch={refetch} />
            </Grid>
          ))}
        </Grid>
      )}
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        sx={{ my: 3 }}
      >
        <Pagination
          count={total || 1}
          page={page}
          onChange={handleChange}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              classes={{
                root: classes.paginationItem,
                selected: classes.selected,
              }}
              {...item}
            />
          )}
        />
      </Container>
    </Container>
  );
}

export default TagPosts;
