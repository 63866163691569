import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { auth } from "./firebase";

const authLink = setContext(async (_, { headers }) => {
  const token = await auth.currentUser
    ?.getIdToken(true)
    .then((idToken) => {
      return idToken;
    })
    .catch((error) => {
      console.log(error);
    });

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        horseSearch: {
          keyArgs: ["query", ["searchTerm"]],
          merge(existing = [], incoming, { args }) {
            if ((args?.query?.offset || 0) > 0) {
              return [...existing, ...incoming];
            } else {
              return [...incoming];
            }
          },
        },
      },
    },
  },
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
