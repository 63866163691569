// @ts-ignore
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { makeStyles } from '@mui/styles';
import { IFileObject } from '../../model';

const useStyles = makeStyles(() => ({
  item: {
    margin: 'auto !important',
    maxWidth: '80% !important',
    flexBasis: 'auto !important',
  },
}));

interface PageProps {
  files: IFileObject[];
  handleAdd: (newFiles: IFileObject[]) => void;
  handleDelete: () => void;
}

export default function DropzoneArea({
  files,
  handleAdd,
  handleDelete,
}: PageProps) {
  const styles = useStyles();
  return (
    <DropzoneAreaBase
      acceptedFiles={['image/*']}
      dropzoneText={'Drag and drop an image here or click'}
      filesLimit={1}
      fileObjects={files}
      previewGridClasses={{
        item: styles.item,
      }}
      showAlerts={['error']}
      onAdd={handleAdd}
      onDelete={handleDelete}
      onAlert={(message: string, variant: string) =>
        console.log(`${variant}: ${message}`)
      }
    />
  );
}
