import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { NOTIFY_USERS, REMOVE_EDITORLIST } from "../../gql";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import {
  Alert,
  Backdrop,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Snackbar,
  Link as MuiLink,
} from "@mui/material";

import { useMutation } from "@apollo/client";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import Box from "@mui/material/Box/Box";
import { IEditorList, IHorse, IPost, NotifiableUser } from "../../model";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
});

export default function EditorList({
  item,
  refetch,
}: {
  item: IEditorList;
  refetch: () => void;
}) {
  const {
    id,
    title,
    bannerSv,
    horses,
    posts,
    status,
    kind,
    notifiableUsers,
    hero,
    mandatory,
  } = item;
  const [deleteEditorList, { loading: deleteLoading }] =
    useMutation(REMOVE_EDITORLIST);
  const [notifyUsers, { loading: notifyLoading }] = useMutation(NOTIFY_USERS);

  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const [notifyOpen, setNotifyOpen] = useState<boolean>(false);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState<boolean>(false);

  const handleRemove = async () => {
    deleteEditorList({ variables: { id } })
      .then(() => {
        refetch();
      })
      .finally(() => setRemoveOpen(false));
  };

  const handleNotify = async (all?: boolean) => {
    try {
      const usersToNotify =
        (all
          ? notifiableUsers
          : notifiableUsers.filter((u) => !u.isNotified)
        )?.map((u) => u.id) || [];

      notifyUsers({
        variables: {
          input: { listId: id, userIds: usersToNotify },
        },
      }).then(() => {
        setSnackbarOpen(true);
        setNotifyOpen(false);
      });
    } catch (e) {
      setSnackbarErrorOpen(true);
    }
  };

  const getNumbers = (horses: IHorse[], posts: IPost[]): string => {
    if (horses.length > 0) {
      return `${horses.length} Horse${horses.length !== 1 ? "s" : ""}`;
    } else if (posts.length > 0) {
      return `${posts.length} Post${posts.length !== 1 ? "s" : ""}`;
    } else {
      return "Banner only";
    }
  };

  const link = encodeURIComponent(`https://floccs.com/?path=lists&id=${id}`);
  const previewLink = `https://floccs.page.link/?link=${link}&apn=com.floccs.app`;

  return (
    <>
      <Paper
        sx={{
          m: 2,
          overflow: "hidden",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
      >
        <Grid container>
          <Grid item>
            {hero?.url || bannerSv?.url ? (
              <Img alt="complex" src={hero?.url || bannerSv?.url} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm container sx={{ p: 2 }}>
            <Grid item xs container direction="column" spacing={2}>
              {title && title[0]?.value && (
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {title[0]?.value}
                  </Typography>
                </Grid>
              )}
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {getNumbers(horses || [], posts || [])}
                </Typography>
              </Grid>
              <Grid item xs sx={{ display: kind === "AD" ? "none" : "auto" }}>
                <MuiLink href={previewLink}>
                  <p>Preview link</p>
                </MuiLink>
              </Grid>
              <Grid item xs container sx={{ justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to={`${
                      kind ? kind.toLocaleLowerCase() : "horse"
                    }/edit/${id}`}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                      sx={{
                        color: "#2196F3",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      EDIT
                    </Typography>
                  </Link>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    sx={{
                      color: "#C95C54",
                      cursor: "pointer",
                      fontWeight: "bold",
                      mx: 3,
                    }}
                    onClick={() => setRemoveOpen(true)}
                  >
                    REMOVE
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    color="Highlight"
                    sx={{
                      display: kind === "AD" ? "none" : "auto",
                      color: "#675CFF",
                      cursor: "pointer",
                      fontWeight: "bold",
                      mx: 3,
                    }}
                    onClick={() => setNotifyOpen(true)}
                  >
                    NOTIFY
                  </Typography>
                </Box>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{
                    color: status === "ACTIVE" ? "#5BB85D" : "gray",
                    cursor: "pointer",
                    fontWeight: "bold",
                    px: 3,
                    border: `2px solid ${
                      status === "ACTIVE" ? "#5BB85D" : "gray"
                    }`,
                    borderRadius: "4px",
                  }}
                >
                  {status === "ACTIVE"
                    ? mandatory
                      ? "PUBLISHED IN CAROUSEL"
                      : "PUBLISHED"
                    : "UNPUBLISHED"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={removeOpen}
        onClose={() => setRemoveOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ textAlign: "center", my: 5 }}>
          Are you sure you want to permanently delete the list?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleRemove} sx={{ color: "red" }}>
            Delete list
          </Button>
          <Button onClick={() => setRemoveOpen(false)} autoFocus>
            Cancel
          </Button>
        </DialogActions>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleteLoading}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Dialog
        open={notifyOpen}
        onClose={() => setRemoveOpen(false)}
        maxWidth="xs"
      >
        <DialogTitle sx={{ textAlign: "center", my: 5 }}>
          Notify users for {title?.length ? title[0]?.value : ""} list
        </DialogTitle>
        <DialogContent>
          {notifiableUsers?.map(({ id, name, isNotified }: NotifiableUser) => (
            <DialogContentText key={id}>
              {name} {isNotified ? "(Previously got notification)" : ""}
            </DialogContentText>
          )) || <></>}
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={() => handleNotify(true)} sx={{ color: "red" }}>
            {`Notify all (${notifiableUsers?.length}) users`}
          </Button>
          <Button onClick={() => handleNotify(false)} sx={{ color: "purple" }}>
            {`Notify un-notified users (${
              notifiableUsers?.filter((u: NotifiableUser) => !u.isNotified)
                .length
            })`}
          </Button>
          <Button onClick={() => setNotifyOpen(false)} autoFocus>
            Cancel
          </Button>
        </DialogActions>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={notifyLoading}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Successfully sent push notifications!
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarErrorOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarErrorOpen(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error sending push notifications
        </Alert>
      </Snackbar>
    </>
  );
}
