import { useMemo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, TextField, Typography } from '@mui/material';
import { IPublishItem } from '../../model';
import { preventSubmit } from '../../utils';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function PublishListItem({
  item,
  updateDate,
}: {
  item: IPublishItem;
  updateDate: (
    newDate: Pick<IPublishItem, 'startDate'> | Pick<IPublishItem, 'endDate'>
  ) => void;
}) {
  const { title, startDate, endDate } = item;

  const isChecked = useMemo(() => {
    if (!startDate) return false;
    if (dayjs(startDate).isAfter(Date.now())) return false;
    if (endDate && dayjs(endDate).isBefore(Date.now())) return false;
    return true;
  }, [startDate, endDate]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item display="flex" alignItems="center">
        <Checkbox
          {...label}
          checked={isChecked}
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CircleIcon />}
        />
        <Typography>{title}</Typography>
      </Grid>
      <Grid item alignItems="center">
        <DatePicker
          label="Start date"
          value={startDate}
          onChange={(newValue: Dayjs | null) => {
            updateDate({ startDate: newValue });
          }}
          renderInput={(params) => (
            <TextField onKeyDown={preventSubmit} {...params} />
          )}
        />
        <DatePicker
          label="End date"
          value={endDate}
          onChange={(newValue: Dayjs | null) => {
            updateDate({ endDate: newValue });
          }}
          renderInput={(params) => (
            <TextField onKeyDown={preventSubmit} {...params} />
          )}
        />
      </Grid>
    </Grid>
  );
}
