import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, CircularProgress, Typography, Skeleton } from "@mui/material";
import { IMedia, ISelectedItem } from "../../../model";
import {
  isCloudflareVideo,
  constructImageUrl,
  constructVideoUrl,
} from "../../../utils";

interface Props {
  loading: boolean;
  items?: ISelectedItem[];
  selected: string[];
  handleSelect: (selectedHorse: ISelectedItem) => void;
}

interface ListProps {
  id: string;
  title: string;
  description: string;
  image: IMedia[];
  selected: string[];
  handleSelect: () => void;
}

const ListItem: React.FC<ListProps> = ({
  id,
  title,
  description,
  image,
  selected,
  handleSelect,
}) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const handleError = useCallback(() => {
    setImgSrc(null);
  }, []);

  useEffect(() => {
    if (isCloudflareVideo(image[0]?.cloudflare)) {
      if (constructVideoUrl(image)) {
        setVideoSrc(constructVideoUrl(image) || "");
        const iframe = document.createElement("iframe");
        iframe.src = constructVideoUrl(image) || "";
        iframe.onerror = () => {
          setVideoSrc(null);
        };
      }
    } else {
      if (constructImageUrl(image)) {
        setImgSrc(constructImageUrl(image) || "");
        const img = new Image();
        img.src = constructImageUrl(image) || "";
        img.onerror = () => {
          setImgSrc(null);
        };
      }
    }
  }, [image]);

  return (
    <ImageListItem
      key={id}
      sx={{
        border: 5,
        borderColor: selected.includes(id) ? "primary.main" : "white",
      }}
    >
      {isCloudflareVideo(image[0]?.cloudflare) ? (
        videoSrc ? (
          <iframe
            title="Video"
            style={{ width: "100%", minHeight: "300px", border: "none" }}
            src={videoSrc}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
          />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%" }}
            height={300}
            animation="wave"
          />
        )
      ) : imgSrc ? (
        <img
          src={`${imgSrc}?w=248&fit=crop&auto=format`}
          srcSet={`${imgSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={title}
          loading="lazy"
          onError={handleError}
        />
      ) : (
        <Skeleton variant="rectangular" height={300} animation="wave" />
      )}
      <ImageListItemBar
        title={title}
        subtitle={description}
        actionIcon={
          <IconButton
            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
            onClick={handleSelect}
          >
            {selected.includes(id) ? (
              <CheckBoxIcon />
            ) : (
              <CheckBoxOutlineBlankIcon />
            )}
          </IconButton>
        }
      />
    </ImageListItem>
  );
};

export default function SelectGrid({
  items,
  loading,
  selected,
  handleSelect,
}: Props) {
  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="600px"
      >
        <CircularProgress size={50} sx={{ color: "#7B61FF" }} />
      </Box>
    );
  }

  if (!items || !items.length) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="600px"
      >
        <Typography variant="h5">Found no items...</Typography>
      </Box>
    );
  }

  return (
    <ImageList variant="masonry" cols={5} gap={8}>
      {items?.map((item) => (
        <ListItem
          key={item.id}
          {...item}
          image={item.img}
          selected={selected}
          handleSelect={() => handleSelect(item)}
        />
      ))}
    </ImageList>
  );
}
