import React, { useEffect, useState, useCallback } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, Typography, Skeleton } from "@mui/material";
import { ISelectedItem } from "../../model";
import {
  isCloudflareVideo,
  constructVideoUrl,
  constructImageUrl,
} from "../../utils";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import ResponsiveGrid from "../responsiveGrid";
import { DnDCard } from "./DnDCard";
import { ImageDialog } from "./ImageDialog";

interface PageProps {
  title: string;
  items: ISelectedItem[];
  handleSelect: (selectedItem: ISelectedItem) => void;
  setOrder: React.Dispatch<React.SetStateAction<ISelectedItem[]>>;
}

interface SelectedProps {
  item: ISelectedItem;
  handleSelect: (selectedItem: ISelectedItem) => void;
  handleClickOpen: (item: ISelectedItem) => void;
}

const ListItem = ({ item, handleSelect, handleClickOpen }: SelectedProps) => {
  const { title, img: image } = item;

  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const handleError = useCallback(() => {
    setImgSrc(null);
  }, []);

  useEffect(() => {
    if (isCloudflareVideo(image[0]?.cloudflare)) {
      if (constructVideoUrl(image)) {
        setVideoSrc(constructVideoUrl(image) || "");
        const iframe = document.createElement("iframe");
        iframe.src = constructVideoUrl(image) || "";
        iframe.onerror = (e) => {
          setVideoSrc(null);
        };
      }
    } else {
      if (constructImageUrl(image)) {
        setImgSrc(constructImageUrl(image) || "");
        const img = new Image();
        img.src = constructImageUrl(image) || "";
        img.onerror = () => {
          setImgSrc(null);
        };
      }
    }
  }, [image]);

  return (
    <ImageListItem
      sx={{
        border: 5,
        borderColor: "white",
        flexShrink: 0,
        width: 133,
        height: "200px !important",
        overflow: "hidden",
        cursor: "pointer",
        "& > img": {
          transition: "transform ease 0.4s",
        },
        "&:hover img": {
          transform: "scale(1.1)",
        },
      }}
      onClick={() => {
        handleClickOpen(item);
      }}
    >
      {isCloudflareVideo(image[0]?.cloudflare) ? (
        videoSrc ? (
          <iframe
            title="Video"
            style={{ width: "100%", height: "100%", border: "none" }}
            src={videoSrc}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen
          />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%" }}
            height={300}
            animation="wave"
          />
        )
      ) : imgSrc ? (
        <img
          src={`${imgSrc}?w=248&fit=crop&auto=format`}
          srcSet={`${imgSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={title}
          loading="lazy"
          onError={handleError}
        />
      ) : (
        <Skeleton variant="rectangular" height={300} animation="wave" />
      )}
      <ImageListItemBar
        actionIcon={
          <IconButton
            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
            onClick={(e) => {
              e.stopPropagation();
              handleSelect(item);
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
      />
    </ImageListItem>
  );
};

export default function PreviewSelectedItems({
  title,
  items,
  handleSelect,
  setOrder,
}: PageProps) {
  const [selectedItem, setSelectedItem] = useState<ISelectedItem | null>(null);

  const handleClickOpen = (item: ISelectedItem) => {
    setSelectedItem(item);
  };
  const handleClose = () => {
    setSelectedItem(null);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setOrder((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Divider variant="middle" sx={{ marginY: 2 }} />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Selected {title}
        </Typography>
        <ResponsiveGrid gridProps={{ border: "3px dashed lightgrey", mt: 1 }}>
          {items?.map((item: ISelectedItem, index: number) => (
            <DnDCard
              key={item.id}
              index={index}
              id={item.id}
              moveCard={moveCard}
            >
              <ListItem
                key={`post-list-${index}`}
                item={item}
                handleClickOpen={handleClickOpen}
                handleSelect={handleSelect}
              />
            </DnDCard>
          ))}
        </ResponsiveGrid>
        <ImageDialog
          open={selectedItem ? true : false}
          selectedItem={selectedItem}
          onRemove={handleSelect}
          onClose={handleClose}
        />
      </DndProvider>
    </>
  );
}
