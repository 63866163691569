import { useEffect, useState, useCallback } from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import { Skeleton } from "@mui/material";
import { ISelectedItem } from "../../model";
import {
  isCloudflareVideo,
  constructVideoUrl,
  constructImageUrl,
} from "../../utils";

interface IImageDialog {
  open: boolean;
  selectedItem: ISelectedItem | null;
  onClose: () => void;
  onRemove: (selectedItem: ISelectedItem) => void;
}

export const ImageDialog = ({
  open,
  onClose,
  onRemove,
  selectedItem,
}: IImageDialog) => {
  const { img: image, title, description } = selectedItem ?? {};
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);
  const handleError = useCallback(() => {
    setImgSrc(null);
  }, []);

  useEffect(() => {
    if (image)
      if (isCloudflareVideo(image[0]?.cloudflare)) {
        if (constructVideoUrl(image)) {
          setVideoSrc(constructVideoUrl(image) || "");
          const iframe = document.createElement("iframe");
          iframe.src = constructVideoUrl(image) || "";
          iframe.onerror = () => {
            setVideoSrc(null);
          };
        }
      } else {
        if (constructImageUrl(image)) {
          setImgSrc(constructImageUrl(image) || "");
          const img = new Image();
          img.src = constructImageUrl(image) || "";
          img.onerror = () => {
            setImgSrc(null);
          };
        }
      }
  }, [image]);

  if (!selectedItem) return null;

  const handleRemove = () => {
    onRemove(selectedItem);
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <ImageListItem sx={{ background: "black" }}>
        {image && isCloudflareVideo(image[0]?.cloudflare) ? (
          videoSrc ? (
            <iframe
              title="Video"
              style={{
                width: "100%",
                height: "100%",
                minHeight: "500px",
                border: "none",
              }}
              src={videoSrc}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowFullScreen
            />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%" }}
              height={300}
              animation="wave"
            />
          )
        ) : imgSrc ? (
          <img
            src={`${imgSrc}?w=248&fit=crop&auto=format`}
            srcSet={`${imgSrc}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={title}
            loading="lazy"
            onError={handleError}
          />
        ) : (
          <Skeleton variant="rectangular" height={300} animation="wave" />
        )}
        <ImageListItemBar
          title={title}
          subtitle={description}
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              onClick={() => {
                handleRemove();
              }}
            >
              <DeleteIcon />
            </IconButton>
          }
          sx={{ position: "sticky" }}
        />
      </ImageListItem>
    </Dialog>
  );
};
