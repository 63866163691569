import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import { useMutation, useQuery } from "@apollo/client";
import PublishListGroup from "../components/publishListGroup";
import {
  IEditorList,
  IFileObject,
  IOption,
  IPublishItem,
  ISelectedItem,
} from "../model";
import {
  HORSE_IMAGE_SHAPES,
  INITIAL_PUBLIC_AREA,
  PUBLISH_TITLES,
} from "../constant";
import SelectTagGroup from "../components/selectTagGroup";
import BannerUploader from "../components/bannerUploader";
import PreviewSelectedItems from "../components/previewSelectedItems";
import SelectPagination from "../components/selectPagination";
import { CREATE_EDITORLIST, EDIT_EDITORLIST, GET_EDITORLISTS } from "../gql";
import { mapKeysToOptions, preventSubmit } from "../utils";
import Disciplines from "../constant/disciplines.json";
import Breeds from "../constant/breeds.json";

export default function CreateEditPost() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [titleSv, setTitleSv] = useState<string>("");
  const [titleEn, setTitleEn] = useState<string>("");

  const [subtitleSv, setSubtitleSv] = useState<string>("");
  const [subtitleEn, setSubtitleEn] = useState<string>("");
  const [shape, setShape] = useState<string>("SQUARE");

  const [disciplines, setDisciplines] = useState<IOption[]>([]);
  const [breeds, setBreeds] = useState<IOption[]>([]);
  const [sponsored, setSponsored] = useState<boolean>(false);

  const [publishArea, setPublishArea] =
    useState<IPublishItem[]>(INITIAL_PUBLIC_AREA);

  const [heroUrl, setHeroUrl] = useState<string>("");
  const [heroFiles, setHeroFiles] = useState<IFileObject[]>([]);
  const [link, setLink] = useState<string>("");

  const [errorMsg, setErrorMsg] = useState<string>("");

  const [selectedPosts, setSelectedPosts] = useState<ISelectedItem[]>([]);

  const updateBannerUrl = (value: string) => {
    setHeroUrl(value);
    setErrorMsg("");
  };

  const handleSelect = (selectedPost: ISelectedItem) => {
    setSelectedPosts(
      selectedPosts.some((post) => post.id === selectedPost.id)
        ? selectedPosts.filter((post) => post.id !== selectedPost.id)
        : [selectedPost, ...selectedPosts]
    );
  };

  const [editEditorList] = useMutation(EDIT_EDITORLIST);
  const [createEditorList, { error, loading }] = useMutation(CREATE_EDITORLIST);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (link !== "" && !link.startsWith("https://")) {
      setErrorMsg("Invalid url.");
      return;
    }
    const inputData = {
      heroUrl,
      shape,
      postIds: selectedPosts.map((post) => post.id),
      disciplines: disciplines?.map((d) => d.value),
      breeds: breeds?.map((b) => b.value),
      publishArea: publishArea.map((item) => ({
        location: item.location,
        startDate: item.startDate,
        endDate: item.endDate,
      })),
      title: [
        {
          value: titleSv,
          locale: "sv_SE",
        },
        {
          value: titleEn,
          locale: "en_US",
        },
      ],
      subtitle: [
        {
          value: subtitleSv,
          locale: "sv_SE",
        },
        {
          value: subtitleEn,
          locale: "en_US",
        },
      ],
      kind: "POST",
      link,
      sponsored,
    };
    (id
      ? editEditorList({
          variables: {
            input: {
              id,
              ...inputData,
            },
          },
        })
      : createEditorList({ variables: { input: inputData } })
    ).then(() => navigate("/"));
  };

  const { data: editorListData, loading: loadingGetEditorListData } = useQuery<{
    editorLists: IEditorList[];
  }>(GET_EDITORLISTS, {
    variables: {
      id: id,
    },
    skip: !id,
  });

  useEffect(() => {
    if (editorListData && editorListData.editorLists.length > 0) {
      const currentData = editorListData.editorLists[0];
      if (currentData.title && currentData.title.length > 0) {
        setTitleSv(currentData.title[0].value || "");
        setTitleEn(currentData.title[1]?.value || "");
      }
      setSubtitleSv(
        currentData?.subtitle && currentData.subtitle?.length > 0
          ? currentData?.subtitle[0]?.value
          : ""
      );
      setSubtitleEn(
        currentData?.subtitle && currentData.subtitle?.length > 1
          ? currentData?.subtitle[1]?.value
          : ""
      );
      setShape(currentData.shape);
      if (currentData?.sponsored) {
        setSponsored(true);
      } else {
        setSponsored(false);
      }

      setBreeds(mapKeysToOptions(Breeds.breeds, currentData.breeds || []));
      setDisciplines(
        mapKeysToOptions(Disciplines.disciplines, currentData.disciplines || [])
      );

      setPublishArea(
        currentData.publishArea?.map((area) => ({
          title: PUBLISH_TITLES[area.location],
          startDate: area.startDate,
          endDate: area.endDate,
          location: area.location,
        })) || []
      );

      setHeroUrl(currentData.hero?.url || "");
      setLink(currentData.link || "");

      setSelectedPosts(
        currentData.posts?.map((post) => ({
          id: post.id,
          title: post.title,
          description: post.caption,
          img: post.media,
        })) || []
      );

      if (currentData.hero?.url) {
        fetch(currentData.hero.url).then(async (r) => {
          const blob = await r.blob();
          const file = new File([blob], "image.jpg", { type: blob.type });
          setHeroFiles([{ file, data: currentData.hero?.url ?? "" }]);
        });
      }
    }
  }, [editorListData]);

  if (loading || loadingGetEditorListData)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="600px"
      >
        <CircularProgress size={50} sx={{ color: "#7B61FF" }} />
      </Box>
    );
  if (error) return <Typography>Något gick fel: {error.message}</Typography>;

  return (
    <Container sx={{ my: 2, mx: "auto" }}>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
        }}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Typography variant="h4" textAlign="right" sx={{ mb: 2 }}>
          {id ? "Edit" : "Create"} Post
        </Typography>

        <Grid container gap={1}>
          <Grid item xs={12} md={5}>
            <TextField
              required
              fullWidth
              id="post-title-sv"
              label="Title"
              onChange={(e) => setTitleSv(e.target.value)}
              value={titleSv}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="post-title-en"
              label="Title in English (Optional)"
              onChange={(e) => setTitleEn(e.target.value)}
              value={titleEn}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="post-subtitle-en"
              label="Subtitle in Swedish (Optional)"
              onChange={(e) => setSubtitleSv(e.target.value)}
              value={subtitleSv}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="post-subtitle-en"
              label="Subtitle in English (Optional)"
              onChange={(e) => setSubtitleEn(e.target.value)}
              value={subtitleEn}
              onKeyDown={preventSubmit}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="outlined-shape-currency"
              select
              label="Shape"
              value={shape}
              onChange={(e) => setShape(e.target.value)}
              onKeyDown={preventSubmit}
            >
              {HORSE_IMAGE_SHAPES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <SelectTagGroup
          disciplines={disciplines}
          breeds={breeds}
          setDisciplines={setDisciplines}
          setBreeds={setBreeds}
        />
        <PublishListGroup
          publishArea={publishArea}
          setPublishArea={setPublishArea}
        />
        <BannerUploader
          type="Hero"
          titleSv={titleSv}
          link={link}
          svFiles={heroFiles}
          errorMsg={errorMsg}
          updateLink={(value: string) => {
            setLink(value);
            setErrorMsg("");
          }}
          sponsored={sponsored}
          setSponsored={setSponsored}
          setSvFiles={setHeroFiles}
          updateBannerUrl={updateBannerUrl}
          updateErrorMsg={() => setErrorMsg("")}
        />
        <PreviewSelectedItems
          title="Post"
          items={selectedPosts}
          handleSelect={handleSelect}
          setOrder={setSelectedPosts}
        />
        <SelectPagination
          isPost
          selectedIds={selectedPosts.map((post) => post.id)}
          handleSelect={handleSelect}
        />

        <Divider variant="middle" sx={{ marginY: 2 }} />
        <Box sx={{ mt: 3, mx: "auto", width: 200, textAlign: "center" }}>
          <Button variant="outlined" type="submit">
            {id ? "Update List" : "Skapa lista"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
