import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { IEditorList } from "./model";
import { GET_EDITORLISTS } from "./gql";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListCard from "./components/listCard.tsx";

function Home() {
  const { data, loading, error, refetch } = useQuery<{
    editorLists: IEditorList[];
  }>(GET_EDITORLISTS);

  const [tab, setTab] = useState<string>("ALL");

  const updateTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (refetch) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="600px"
      >
        <CircularProgress size={50} sx={{ color: "#7B61FF" }} />
      </Box>
    );
  if (error) return <Typography>Något gick fel: {error.message}</Typography>;

  return (
    <Container>
      <Grid
        container
        justifyContent="flex-end"
        gap={2}
        sx={{ margin: 2, px: 3 }}
      >
        <Link to="/horse/create">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
            sx={{ borderRadius: 40, lineHeight: "1.25" }}
          >
            Create <br /> Horse list
          </Button>
        </Link>
        <Link to="/post/create">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
            sx={{ borderRadius: 40, lineHeight: "1.25" }}
          >
            Create <br /> Post list
          </Button>
        </Link>
        <Link to="/ad/create">
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="large"
            sx={{ borderRadius: 40, lineHeight: "1.25" }}
          >
            Create <br /> AD
          </Button>
        </Link>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={updateTab}
          aria-label="lab API tabs example"
        >
          <Tab label="All" value="ALL" />
          <Tab label="Horses" value="HORSE" />
          <Tab label="Posts" value="POST" />
          <Tab label="Banners" value="AD" />
        </Tabs>
      </Box>
      <Grid container rowSpacing={2} spacing={2}>
        {data?.editorLists
          ?.filter((item) => tab === "ALL" || tab === item.kind)
          .map((item) => (
            <Grid item xs={6} md={6} key={item.id}>
              <ListCard item={item} refetch={refetch} />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}

export default Home;
