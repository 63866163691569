import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Logo from "./assets/logo-white.png";
import { Link } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";

export default function NavBar() {
  const auth = getAuth();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            <Link to="/">
              <Box
                component="img"
                sx={{
                  height: 40,
                }}
                alt="Your logo."
                src={Logo}
              />
            </Link>
            <Link to="/tag-posts">
              <Typography component='div' sx={{ color: '#FFF' }}>
                TAG POSTS
              </Typography>
            </Link>
          </Box>

          <Button onClick={() => signOut(auth)} color="inherit">
            SIGN OUT
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
